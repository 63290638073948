// @flow

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import type { GatsbyImageSizes } from "gatsby-image";
import Layout from "../components/Layout";
import Link from "../components/Link";

type Props = {
  data: { file: { childImageSharp: { sizes: GatsbyImageSizes } } },
};
export default ({
  data: {
    file: {
      childImageSharp: { sizes },
    },
  },
}: Props) => (
  <Layout>
    <h1>Oooops!</h1>
    <h2>We couldn&apos;t find anything here...</h2>
    <p>
      You can try the <Link to="/">homepage</Link>, or our{" "}
      <Link to="/recipes/">recipes page</Link>!
    </p>
    <Img sizes={sizes} />
  </Layout>
);

export const query = graphql`
  query NotFoundQuery {
    file(relativePath: { eq: "img/bee.png" }) {
      childImageSharp {
        sizes(maxWidth: 768) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
